import React, { useContext, useEffect, useMemo, useState } from "react"

import { IntersectionContext } from "@atoms/IntersectionObserver/IntersectionObserver"
import { MotionBox } from "@atoms/MotionBox/MotionBox"
import { StaggerContext } from "@atoms/StaggerWrap/StaggerWrap"

export const FadeInUpBox = ({
  children,
  yOffset = 24, // y initial position
  easing = [0.42, 0, 0.58, 1], // [number, number, number, number] | "linear" | "easeIn" |
  // "easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" |
  // "backInOut" | "anticipate" | EasingFunction
  duration = 0.4,
  delayOrder, // order of appearance
  enabled = true,
  styleName,
  ...rest
}) => {
  const { inView } = useContext(IntersectionContext)
  const { stagger } = useContext(StaggerContext)
  const [delay, setDelay] = useState(0.3)

  const offset = 0.4

  useEffect(() => {
    if (delayOrder) return setDelay(delayOrder * offset)
  }, [delayOrder, offset])

  const transition = useMemo(
    () => ({
      duration,
      delay,
      ease: easing,
    }),
    [duration, delay, easing]
  )

  const staggerTransition = {
    duration,
    ease: easing,
  }

  const variants = {
    hidden: {
      y: yOffset,
      opacity: 0,
      transition,
    },
    show: {
      y: 0,
      opacity: 1,
      transition: stagger ? staggerTransition : transition,
    },
  }

  const isStaggered = (
    <MotionBox
      className={`${styleName} fade-in-up-box motion-box`}
      variants={variants}
      {...rest}
    >
      {children}
    </MotionBox>
  )

  const notStaggered = enabled ? (
    <MotionBox
      className={`${styleName} fade-in-up-box motion-box`}
      initial="hidden"
      animate={inView ? "show" : "hidden"}
      exit="hidden"
      variants={variants}
      {...rest}
    >
      {children}
    </MotionBox>
  ) : (
    <div>{children}</div>
  )

  return stagger ? isStaggered : notStaggered
}
